.nav-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 20px;
    // transition: all .3s;
    position: relative;
    transition: all 0.3s;
    background-color: transparent;
    margin: 2px 0;
    height: 57px;
    .nav-link {
        color: #FFFFFF;
      font-size: 20px;
      transition: all 0.3s;
      text-transform: uppercase;
      
    }
  }

  .active-navlink .nav-block {
    background-color: #28232E;
    p{
        color: #E58260;
    }
  }
  