div.smart-path__stats {
  padding-top: 20px !important;
}
.smart-path {
  &__stats {
    h3 {
      color: #e58260;
      font-size: 30px;
    }
    p {
      font-size: 16px;
      color: white;
    }
  }
  &__box {
    background-color: rgba(51, 45, 59, 0.658);
    padding: 20px;
    .smart-icon {
      color: #e58260;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 680px) {
  div.smart-path__stats {
    padding-top: 0px !important;
  }
  .smart-path-close {
    font-size: 1rem !important;
  }
}