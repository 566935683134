*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

.login__logo {
  width:450px
}

body {
  box-sizing: border-box;
  overflow-x: hidden !important;
  font-family: "Neuzeit Grotesk" !important;
  // font-family: $primary-font-family;
  font-size: 1rem;
  // font-weight: 600;
  color: $text-color;
  background-color: #fff;
  padding-right: 0 !important;
}

.container-p-l {
  padding-left: 10%;
}

.container-p-r {
  padding-right: 10%;
}

.container-m-l {
  margin-left: 10%;
}

.container-m-r {
  margin-right: 10%;
}

a,
button {
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    color: inherit;
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

a {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $primary;
  }
}
ul {
  list-style: none;
  li {
    display: inline-block;
  }
}

.cst-overlay {
  .close-btn {
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    font-size: 20px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  background-color: rgba($color: #de6a51, $alpha: 0.8);
  top: 0;
  left: 0;
  z-index: 1100;
  .continue-btn {
    color: #fff;
    border-color: #fff;
    transition: all 0.5s;
    font-weight: 600;
    &:hover {
      background-color: #fff;
      color: #000;
    }
    &:focus {
      outline: none;
    }
  }
}

@media only screen and (max-width: 680px) {
  body {
    font-size: 14px !important;
  }
}

.btn-link:hover {
  color:#fff !important
}