.welcome-slider{
    .slick-track:focus{
        outline: none !important;
    }
    // .slick-dots{
    //     bottom: -188px !important; 
       
    // }
    .slick-dots li button:before{
        font-size: 15px !important;
        color: white ;
    }
    .slick-dots li.slick-active button:before
    {
        color: #e58260;
    }
   
    button.slick-arrow.slick-next {
        display: none !important;
    }
    .slick-slider {
        min-height: 70vh;
    }
}


.welcome-1{
 padding-top: 100px;
    text-align: center;
    p{
        color: white;
        max-width: 500px;
        margin: 0 auto; 
    }
}
.welcome-2{
    padding-top: 100px;
    text-align: center;
    p{
        color: white;
        text-align: start !important;
        margin: 0 auto; 
        max-width: 275px;
        margin-left: 30px;
    }
}
.setup-btn{
    display: flex;
    justify-content: center;
   
}

@media only screen and (max-width: 680px) {
    .welcome-slider{
        .slick-slider {
            min-height: 50vh;
        }
       
    }
    .welcome-1{
        padding: 10px 10px;
        p{
            max-width: 100%;
            font-size: 14px;
        }
    }
    .welcome-2{
        padding: 10px 10px;
        p{
            max-width: 100%;
            font-size: 14px;
        }
    }
   
}

@media only screen and (max-width: 880px) and (min-width: 600px){

  
}