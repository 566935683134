.setup-screen{
    padding-top: 90px;
    text-align: center;
    p{
        color: white;
        font-size: 20px;
    }
}

@media only screen and (max-width: 680px) {
    .setup-screen{
        padding-top: 30px;
}
}