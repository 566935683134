.plant-slider{
    .slick-prev, .slick-next {
        top: -80px;
        width: 25px;
        height: 25px;
    }
    .slick-prev {
        left: auto;
        right: 35px;
    }
    .slick-next {
        right: 5px;
    }
    .slick-prev::before, .slick-next::before{
        font-size: 25px;
        color: #ff7c60;
    }
    .plant-slider__content:focus{
        outline: none;
    }
    &__content{
        h2{
            color: #E58260;
            font-size: 20px;
            text-transform: uppercase;
        }
        p{
            color: #FFFFFF;
            font-size: 16px;
            max-width: 400px;
            width: 95%;
        }
        img{
            width: 100%;
        }
    }
}


@media only screen and (max-width: 690px) {
    .plant-wrapper{
        padding: 0 15px;
    }
    .plant-slider{
        // .slick-prev {
        // left: 390px;
        // }
        .slick-next {
            right: 0;
        }
        .slick-prev, .slick-next {
            top: -69px;
        }
        &__content{
            img{
                width: 50%;
                margin: 0 auto;
            }
            p{
                max-width: 600px;
                width: 95%;
            }
        }
    }
}
@media only screen and (max-width: 1000px) and (min-width: 691px){
    .plant-slider{
        // .slick-prev {
        // left: 340px;
        // }
        &__content{
            img{
                width: 50%;
            }
        }
}
.plant-wrapper{
    padding: 0 15px;
}
}