.profile-box{
.modal-content{
    // max-width: 709px;
    // margin: 0 auto;
    // width: 85%;
}
    &__detail{
        max-width: 505px;
        margin: 0 auto;
        width: 90%;
        .main-heading{
            color: #E58260;
            font-size: 32px;
        }
    }
    &__upload{
        position: relative;
        
        input{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
        img{
            cursor: pointer !important;
            z-index: 999;
            max-width: 120px;
            max-height: 120px;
        }
    }
}

@media screen and (max-width: 767px) {
    .edit-profile-modal {
        .modal-content.fancy-scroll {
            max-height: 85vh;
        }
    }
}