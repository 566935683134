.age-verify{
   
    &__content{
        max-width: 700px;
        width: 80%;
        margin: 0 auto;
        img{
            width: 100%;
        }
        p{
            color: #FFFFFF;
            font-size: 22px;
            max-width: 620px;
            text-align: center;
            margin: 0 auto;
        }
        &__button{
            margin: 0 auto;
            max-width: fit-content;
        }
    }
}

@media only screen and (max-width: 680px) {
    .age-verify{
        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 10px;
            p{
                font-size: 18px;
            }
            img{
                width: 85%;
            }
        }
    }
}

.fw-bar-wrapper {
    width: 100%;
}

.fw-progress-bar {
    width: 100%;
    background-color: #3f3535 !important;
    padding: 3px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.fw-progress-bar-fill {
    display: block;
    height: 22px;
    background-color: #E58260;
    border-radius: 3px;
    
    transition: width 500ms ease-in-out;
}