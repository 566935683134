.input-feild {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    margin-bottom: 15px;
    padding: 12px 15px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #FFFFFF;
    .border_red {
      border: 1px solid #ff0000 !important;
    }
  
    .input-text {
      width: 100%;
      height: 100%;
    }
    .icon {
      font-size: 16px;
    }
    .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin-right: 15px;
    
    }
    .eyeprop {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      &:focus{
        outline: none;
    }
    }
  
    input {
      background-color: transparent !important;
      border: none;
      font-size: 14px;
      width: 100%;
      height: 100%;
      color: white;
      &:focus,
      &:hover {
        outline: none;
        border: none;
      }
    }
  }
  