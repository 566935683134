@media only screen and (max-width: 880px) {
    .setup-slider{
        &__image{
           display: flex;
           flex-direction: column;
           align-items: center;
           max-width: 100%;
        }
           &__1{
               img{
                   margin-top: 20px;
                   width: 60%;
               }
               
           }
       }
}
