.battery-icon-mob {
  display: none;
}

.session-screen {
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-bottom: 4px solid transparent;

    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: #e58260;
  }
  li.nav-item {
    background: transparent;
  }
  a.nav-link {
    font-size: 20px !important;
    color: white !important;
    padding: 10px 60px;
  }
  a.nav-link.active {
    background-color: #201b23;
    border-bottom: 4px solid #e58260;

    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .dot {
    height: 17px;
    width: 17px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  .charged {
    background-color: #bbb;
  }
}

@media only screen and (max-width: 680px) {
  div.battery-bar-mob-none {
    display: none !important;
  }
  .battery-icon-mob {
    display: block;
    position: relative;
    bottom: -20px;
    float: right;
    right: 15px;
    img {
      max-width: 20px !important;
    }
    span {
      font-size: 10px;
    }
  }
  .session-screen {
    .nav-tabs {
      padding: 0 15px;
    }
    img {
      // width: 20%;
      max-width: 15px;
    }
    .dot {
      height: 10px;
      width: 10px;
    }
    a.nav-link {
      font-size: 1em !important;
    }
  }
  a.nav-link {
    padding: 5px 30px !important;
  }
}
