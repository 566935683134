.stats-star-rating {
  border: 1px solid #635f66;
  float: left;
  height: 50px;
  padding: 0 10px;
  ::after {
    display: table-cell;
    clear: both;
  }
}
.stats-star-rating:not(:checked) > input {
  position:fixed;
  top:-9999px;
  z-index: -999;
}
.stats-star-rating:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#fff;
}
.stats-star-rating:not(:checked) > label:before {
  content: '★ ';
}
.stats-star-rating > input:checked ~ label {
  color: #e58260;    
}
.stats-star-rating:not(:checked) > label:hover,
.stats-star-rating:not(:checked) > label:hover ~ label {
  color: #e58260;  
}
.stats-star-rating > input:checked + label:hover,
.stats-star-rating > input:checked + label:hover ~ label,
.stats-star-rating > input:checked ~ label:hover,
.stats-star-rating > input:checked ~ label:hover ~ label,
.stats-star-rating > label:hover ~ input:checked ~ label {
  color: #e58260;
}
.stats-box {
  p {
    font-size: 18px;
    color: #ffffff;
  }
  &__info {
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    h6 {
      color: #e58260;
      font-size: 18px;
    }
  }
  &__progress {
    &-stats {
      width: 40%;
      margin: 0 auto;
      span {
        color: white;
        font-size: 12px;
      }
    }
  }
  progress {
    background: #241f28;
    border: 1px solid rgba(255, 255, 255, 0.288);
    border-radius: 30px;
  }

  progress::-webkit-progress-value {
    background: #e58260;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }

  progress::-webkit-progress-bar {
    background: #241f28;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 680px) {
  .stats-box {
    &__progress {
      &-stats {
        width: 90%;
      }
    }
    .modal-content {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.rating-progress-bar {
  color: #e58260 !important;
  border: 0;
  /* box-shadow: none; */
  background: transparent;
}
