.login{
    padding-top: 100px;
    &__logo img{
        width: 100%;
    }
    &__field{
        width: 25%;
       
        button{
            margin: 0 auto;
            display: flex;
        }
    }
    &__description{
        padding: 100px 0 80px 0;
    }
}

@media only screen and (max-width: 880px) {
    .login{
        &__logo {
            display: flex;
            justify-content: center;
            img{
            max-width: 80%;
        }
    }
        padding-top: 50px;
        &__field{
            width: 90%;
        }
    }
}