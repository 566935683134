

.btn{
    display: inline-flex;
    justify-content: center !important;
    align-items: center !important;
    padding:  12px 30px !important;

    min-width: 110px;
    font-size: 18px !important;
    outline: none;
    border-radius: 0 !important;
    text-transform: uppercase;
    letter-spacing: 4px;
    .loading{
        animation: spin 2s linear infinite;
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }
    &.btn-round{
        border-radius: 25px !important;
    }
    &.btn-small{
        height: 35px;
        min-width: 100px;
        font-size: 14px;
    }
}
.btn-primary{
    background-color: transparent !important;
    border: 1px solid #fff  !important;
    
    &:focus,
    &:active,
    &:hover{
        outline: none !important;
        box-shadow: none !important;
    }
    &:active,
    &:hover {
        background-color: $primary !important;
        border: 1px solid #fff  !important;
        color: white !important;
    }
}

.btn-dark:hover a {
color: $primary  !important;
}
.btn-dark{
    background-color:  $primary !important;
    border: 1px solid white !important;
    &:focus,
    &:active,
    &:hover{
        outline: none !important;
        box-shadow: none !important;
    }
    &:active,
    &:hover{
        background-color: #fff !important;
        color: $primary !important;
    }
}
.btn-link{
    max-width: fit-content !important;
    margin: 0 auto !important;
    display: flex !important;
    text-decoration: none !important;
    align-items: center !important;
    justify-content: center !important;
}

.btn.device-btn{
    font-size: 0.75em !important;
    min-width: auto;
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.btn.device-btn[data-target="#addmodal"] {
    margin-top: 0px !important;
    font-size: 0px !important;
    border: none !important;
    background: url('../../assets/images/add-device-icon.png') no-repeat center;
    width: 32px;
    height: 32px;
    padding: 0 !important;
}

.btn.device-btn[data-target="#addmodal"]:hover,.btn.device-btn[data-target="#addmodal"]:focus{
    background-color: transparent !important;
}

@media only screen and (max-width: 1260px) {
    .btn{
        padding:  10px 13px !important;
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 680px) {
    .btn {
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-size: 0.75em !important;
        min-width: initial;
    }
    .btn.device-btn {
        font-size: 0.60em !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .btn.device-btn[data-target="#addmodal"] {
        background-size: cover;
        width: 22px;
        height: 22px;
    }
}