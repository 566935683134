

.forgot-box{
&__input{
    width: 40%;
    margin: 0 auto;
    p{
        color: #FFFFFF;
        font-size: 20px;
    }
}
}

@media only screen and (max-width: 680px) {
    .forgot-box{
        &__input{
            width: 100%;
        }
        .modal-content{
            width: 90%;
            margin: 0 auto;
        }
    }
}