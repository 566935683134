.setup-box{
    &__input{
        width: 40%;
    margin: 0 auto;
    }
    p{
        color: white;
    }
    &__device{
        border-top: 1px solid #707070 ;
        border-bottom: 1px solid #707070 ;
        max-width: fit-content;
        margin: 0 auto 30px auto;
        padding-bottom: 10px;
        color: #ffffff83 !important;
    }
    &__description{
        font-size: 12px;
    }
    
}

@media only screen and (max-width: 680px) {
    .setup-box{
        &__input{
            width: 100%;
        }
        .modal-content{
            width: 90%;
            margin: 0 auto;
        }
    }
}