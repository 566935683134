.primary-heading{
  font-size: 40px;
  font-weight: 400;
  color: $primary;
  text-transform: uppercase;
}
.primary-secondry{
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 400;
}

a{
  transition: color .3s ease-in-out;
  color: #fff !important;
  font-size: 18px !important;
  &:hover{
    text-decoration: none !important;
    color: $primary !important;
  }
 
}
.fancy-text{

 transition: color .3s ease-in-out;
  color: #fff !important;
  font-size: 18px;
  background: none;
  border: none;
  &:hover{
    text-decoration: none !important;
    color: $primary !important;
  }
  &:focus{
    outline: none !important;
  }
}
.fancy-scroll{
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    transition: background-color .3s;
  }
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: rgba($primary, 0.8);
    }
  }
}


.modal-backdrop.show {
    opacity: 0.8;
}


ul.bullet{
    li{
        position: relative;
        padding-left: 50px ;
        &:before{
            content: "";
            position: absolute;
            top: 25px;
            left: 30px;
            background-color:  $primary;
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }
}



@media only screen and (max-width: 680px) {
  .primary-heading{
    font-size: 22px;
  }
  .fixed-mod-mob{
    position: fixed;
  }

}


.gm-style-iw button[title="Close"] {
    display: none !important;
}
