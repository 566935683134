.history {
  p {
    color: white;
    font-size: 13px;
  }
  h3 {
    color: #e58260;
    font-size: 19px;
  }
  h4 {
    color: #e58260;
    font-size: 13px;
  }
  &__box {
    background: #332d3b;
    padding: 18px;
    &-left {
      width: 100%;
    }
  }

  // .cst-star-rating {
  //     font-family: fontawesome;
  //     font-weight: 400;
  //     font-variant: normal;
  //     text-transform: none;
  //     line-height: 1;
  //     -webkit-font-smoothing: antialiased;
  //     height: 1em;
  //     width: 5em;
  // }
  .cst-star-rating {
    font-size: 1em;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 66.64px;
  }
  .cst-star-rating:before {
    content: "\☆\☆\☆\☆\☆";
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    color: #e58260;
  }
  .cst-star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
  }
  .cst-star-rating span:before {
    content: "\★\★\★\★\★";
    top: 0;
    position: absolute;
    left: 0;
    color: #e58260;
  }
}
.title-heading {
  color: #e58260;
  font-size: 25px;
}
.history-boxes {
  width: 33%;
}
@media only screen and (max-width: 780px) {
  .history-boxes {
    width: 100%;
  }
  .history-wrapper {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 781px) {
  .history-boxes {
    width: 50% !important;
  }
  .history-wrapper {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 680px) {
  .history {
    // .cst-star-rating {
    //   max-width: 58.31px;
    // }
    p,
    h4 {
      font-size: 0.75em;
    }
  }
}
