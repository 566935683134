.setting-screen {
  position: relative;
  &:after {
    // content: " ";
    // position: absolute;
    // top: 45%;
    // margin-top: -2px;
    // right: 15px;
    // width: 0;
    // height: 0;
    // border-left: 10px solid transparent;
    // border-right: 10px solid transparent;
    // border-top: 10px solid #E58260;
  }
  select {
    width: 40%;
    padding: 20px;
    background: transparent;
    border-color: #ffffff;
    color: white;
    font-size: 24px;
    appearance: none;
    option {
      color: #e58260;
      background: #332d3b;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &__content {
    p {
      color: white;
      font-size: 24px;
      text-transform: uppercase;
    }
    &-box {
      border: 1px solid white;
      padding: 25px 30px;
      margin-bottom: 45px;
      h5 {
        color: rgba(167, 167, 167, 60%);
        font-size: 22px;
      }
    }
  }
}
.notification-toggle {
  h5 {
    color: rgba(167, 167, 167, 60%);
    font-size: 20px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 27px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #332d3b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 27px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #332d3b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: #e58260;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider-off:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: #636669;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #332d3b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #332d3b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media only screen and (max-width: 680px) {
  .setting-screen {
    padding: 0 15px;
    &:after {
      top: 66%;
      right: 30px;
    }
    select {
      width: 100%;
      font-size: 16px;
    }
    &__content {
      padding: 0 15px;
      width: 100%;
      p {
        font-size: 16px;
      }
      &-box {
        padding: 10px;
        margin-bottom: 20px;
        min-height: 66px;
        h5 {
          font-size: 16px;
        }
      }
      &-img {
        img {
          width: 30%;
          max-height: 200px;
        }
      }
    }
  }
}
@media only screen and (max-width: 880px) and (min-width: 690px) {
  .setting-screen {
    padding-top: 30px;
    &:after {
      top: 60%;
    }
    select {
      width: 60%;
      font-size: 16px;
    }
    &__content {
      width: 100%;
      p {
        font-size: 16px;
      }
      &-box {
        padding: 10px;
        margin-bottom: 20px;
        min-height: 66px;
        h5 {
          font-size: 16px;
        }
      }
      &-img {
        img {
          width: 30%;
        }
      }
    }
  }
}
