.age-verify{
   
    &__content{
        max-width: 700px;
        width: 80%;
        margin: 0 auto;
        img{
            width: 100%;
        }
        p{
            color: #FFFFFF;
            font-size: 22px;
            max-width: 620px;
            text-align: center;
            margin: 0 auto;
        }
        &__button{
            margin: 0 auto;
            max-width: fit-content;
        }
    }
}

@media only screen and (max-width: 680px) {
    .age-verify{
        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 10px;
            p{
                font-size: 18px;
            }
            img{
                width: 85%;
            }
        }
    }
}

.highlight-device {
    color: white !important;
}

.mobile-highlight-device {
    color: #e58260 !important;
}
.device-list-loader-lg {
  position: relative;
  top: 0px;
  display: inline-block;
}
.orange-outlined-btn:hover {
    color: #e58260 !important;
}