.setup-slider{
    .slick-slider{
        min-height: 70vh;
    }
    .slick-track:focus{
        outline: none !important;
    }
    .slick-dots{
        bottom: 20px !important; 
    }
    .slick-dots li button:before{
        font-size: 15px !important;
        color: white ;
    }
    .slick-dots li.slick-active button:before
    {
        color: #e58260;
    }
    button.slick-arrow.slick-next {
        display: none !important;
    }
  
&__content{
    max-width: 500px;
    text-align: start;
    display: flex;
    flex-direction: column;
    span{
        color: #E58260;
        font-weight: 600;
    }
}
}

@media only screen and (max-width: 680px) {
    .setup-slider{
        .slick-slider{
            min-height: 80vh !important;
        }
        .slick-dots{
            bottom: 0px !important; 
        }
      
        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100%;
            padding:0px 10px !important;
             
            p{
                font-size: 14px !important;
                padding-bottom: 10px;
                padding-left: 10px;
            }
            img{
                width: 70%;
                
            }
            padding:20px 10px;
            max-width: 100%;
            &-img{
                display: flex;
                justify-content: center;
                img{
                    width: 50%;
                }
            }
        } 
    }
}
@media only screen and (max-width: 880px) and (min-width: 681px) {
    .setup-slider{
        .slick-slider{
            min-height: 60vh;
        }
        .slick-dots{
            bottom: 0px !important; 
        }
       
        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 90%;
            padding:0px 10px;
            &-img{
                display: flex;
                justify-content: center;
                img{
                    width: 80%;
                }
            }
        }
    }

}