div.setup-btn-wrap-hide-large,
.iq-two-mob-session-progress-detail {
  display: none !important;
}

.draw-notif {
  width: 15%;
  position: absolute;
  z-index: 998;
  left: 132px;
  top: 100px;
  background-color: #322c3a;
  border: solid 1px white;
  border-radius: 10px;
  padding: 5px;
  transform: translate(-50%, -50%);
}
.session-progress {
  label {
    color: white;
    font-size: 20px;
    min-width: 180px;
  }
  p {
    color: #ffffff;
    font-size: 10px;
  }
  span {
    min-width: 3%;
    color: #e58260;
    font-size: 16px;
  }
  progress {
    background: #241f28;
    border: 1px solid rgba(255, 255, 255, 0.288);
    border-radius: 30px;
  }

  progress::-webkit-progress-value {
    background: #e58260;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }

  progress::-webkit-progress-bar {
    background: #241f28;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }
  &__detail {
    h4 {
      font-size: 24px;
      color: #ffffff;
      display: inline-block;
    }
    .button-count {
      background: transparent;
      border: 1px solid #fff;
      color: #e58260;
      padding: 5px 15px 5px 15px;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: #e58260;
        color: #fff;
      }
      &.up {
        border-radius: 60px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-bottom: 10px;
      }
      &.down {
        border-radius: 5px;
        border-bottom-right-radius: 60px;
        border-bottom-left-radius: 60px;
      }
    }
    h2 {
      font-size: 34px;
      color: #ffffff;
      font-weight: 600;
      padding-bottom: 10px;
      display: inline-block;
    }
  }
  &__counter {
    max-width: 350px;
  }
}

.iq-two-mob-session-progress-detail {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  .minus-btn-wrap {
    margin-right: 10px;
  }
  .plus-btn-wrap {
    margin-left: 10px;
  }
  .temp-control {
    .target {
      border-top: none;
    }
    .standby {
      margin-top: 5px;
      justify-content: center;
    }
    > :not(.temp) {
      color: #e58260;
    }
    > .temp {
      color: #fff;
    }
    > div {
      display: flex;
      justify-content: space-between;
      padding: 10px 30px;
      border: 1px solid rgba($color: #fff, $alpha: 0.3);
      font-weight: 500;
    }
  }
  .temp-controllers {
    button {
      border-radius: 50% !important;
      border: 1px solid rgba($color: #fff, $alpha: 0.3);
      background-color: #332d3b;
      color: #e58260;
      font-size: 1.2em !important;
      padding: 10px 15px !important;
      &:focus {
        outline: none;
      }
    }
  }
  .smart-path-wrap {
    margin-top: 30px;
    button {
      font-size: 1em !important;
    }
    svg {
      margin: -5px auto 0px;
      display: block;
      font-size: 2em;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .session-progress__detail {
    .select-smart-btn {
      font-size: 0.9375em !important;
    }
  }
}

@media only screen and (max-width: 680px) {
  .iq-two-mob-session-progress-detail {
    display: block !important;
  }
  .draw-notif {
    width: 31%;
  }
  .session-screen {
    .primary-heading,
    .title-heading {
      font-size: 1em;
    }
    .history {
      h3 {
        font-size: 1em;
      }
    }
  }
  div.setup-btn-wrap-hide-mob,
  div.mobile-none {
    display: none !important;
  }
  div.setup-btn-wrap-hide-large {
    display: flex !important;
    width: 100%;
  }
  div.session-progress__detail {
    padding-left: 0 !important;
    margin-top: 10px;
  }
  .session-progress {
    &__detail {
      .button-count {
        padding: 5px 10px !important;
        font-size: 0.75em;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .session-progress__counter {
        max-width: 100%;
        width: 100%;
        h2,
        h4 {
          font-size: 0.75em;
          font-weight: normal;
        }
      }
      > .wrap {
        width: 50%;
        h2,
        h4 {
          font-size: 0.75em;
        }
      }
      .select-smart-btn {
        font-size: 0px !important;
        width: 16px;
        height: 16px;
        border-radius: 50% !important;
        min-width: auto;
        padding: 15px !important;
        margin-top: 0 !important;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        border-color: transparent !important;
        &::after {
          background: url("../../assets/images/lightbulb.png");
          content: "";
          position: absolute;
          top: 7px;
          left: 7px;
          width: 16px;
          height: 16px;
        }
      }
    }
    span,
    label {
      font-size: 0.625em;
      padding-top: 10px;
    }
    p {
      margin-top: -23px !important;
    }
  }
}
