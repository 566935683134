// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Neuzeit Grotesk';
    src: url('../fonts/NeuzeitGro-Bol.eot');
    src: url('../fonts/NeuzeitGro-Bol.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeuzeitGro-Bol.woff2') format('woff2'),
        url('../fonts/NeuzeitGro-Bol.woff') format('woff'),
        url('../fonts/NeuzeitGro-Bol.ttf') format('truetype'),
        url('../fonts/NeuzeitGro-Bol.svg#NeuzeitGro-Bol') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neuzeit Grotesk';
    src: url('../fonts/NeuzeitGro-Lig.eot');
    src: url('../fonts/NeuzeitGro-Lig.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeuzeitGro-Lig.woff2') format('woff2'),
        url('../fonts/NeuzeitGro-Lig.woff') format('woff'),
        url('../fonts/NeuzeitGro-Lig.ttf') format('truetype'),
        url('../fonts/NeuzeitGro-Lig.svg#NeuzeitGro-Lig') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neuzeit Grotesk';
    src: url('../fonts/NeuzeitGro-Reg.eot');
    src: url('../fonts/NeuzeitGro-Reg.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeuzeitGro-Reg.woff2') format('woff2'),
        url('../fonts/NeuzeitGro-Reg.woff') format('woff'),
        url('../fonts/NeuzeitGro-Reg.ttf') format('truetype'),
        url('../fonts/NeuzeitGro-Reg.svg#NeuzeitGro-Reg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@import 'node_modules/react-modal-video/scss/modal-video.scss'; 
$primary : #E58260;
$primary-dark : #ecb50f;
$secondary : #282828;
$text-color: #0A0A0A;
$black : #0A0A0A;
$border-color-primary : #dadada;
$listing-right-panel : #000000;
$popup-bg : #0d0d0d;
$header-height: 54px;
$header-height-desktop: 44px;
$footer-height : 65px;
$footer-height-desktop : 50px;
$min-content-height : calc(100vh - #{$header-height });
$min-content-height-desktop : calc(100vh - #{$header-height-desktop });
$min-height : 500px;

$primary-font-family: 'Mulish', sans-serif;
$secondary-font-family:  'Mulish', sans-serif;
