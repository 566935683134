
.banner-primary{
  background-image: url('../../assets/images/background.png') !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}


.text-primary{
    color : $primary !important;
}

.page-title{
  font-size: 45px;
  color: #0A0A0A;
  margin-bottom: 25px;
}

.f-14{
  font-size: 14px;
}

.pointer{
  cursor: pointer;
}

.text-black{
  color: #000;
}
.close{
  &:focus{
      outline: none !important;
  }
}
// .modal{
//   top: 15% !important;
// }
// .modal-dialog{
//   max-width: 1088px !important;
// }
.modal-content{
  background-color: #231F27 !important;
  border: 2px solid #E58260 !important;
}
.close{
  box-shadow: none !important;
  opacity: 1 !important;
  color: #E58260 !important;
  font-size: 3rem !important;
  float: left !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 background-color: transparent !important;
}

// .modal-dialog{
//   margin: auto !important;
// }

progress {
  background: #241F28;
  border: 1px solid rgba(255, 255, 255, 0.288);
  border-radius: 30px;
}

progress::-webkit-progress-value {
  background: #E58260;
  border-radius: 30px;
  transition: all .3s ease-in-out;
}

progress::-webkit-progress-bar {
  background: #241F28;
  border-radius: 30px;
  transition: all .3s ease-in-out;
}
// .modal {
//  top: 0% !important;
// }

.ox-hidden {
  overflow-x: hidden;
}

.modal-content {
  &.fancy-scroll {
    max-height: 70vh;
  }
}

@media only screen and (max-width: 880px) {
  .banner-primary{
    background-image: url('../../assets/images/mobilebg.png') !important;
  }
}