.cursor-hand {
    cursor: pointer;
}

.device-screen{

    height: 70vh;
p{
    font-size: 20px;
    color: white;
    max-width: 400px;
    text-align: center;
}
&__detail{
    h4{
        color: #E58260;
        font-size: 35px;
    }
    h6{
        color: #E58260;
        font-size: 16px;
        margin: 0;
    }
    p{
        color: white;
        font-size: 16px;
    
    }
}
&__box{
    border-bottom: 1px solid #707070;
}
}

.device-slider {
    * {
        outline: none;        
    }
    .device-item {
        img {
            max-width: 200px !important;
        }
    }
    .slick-arrow {
        z-index: 1;
        &.slick-next{
            right: 0;
        }
        &.slick-prev{
            left: 0;            
        }
    }
}

div.device-btns-large-hidden,.device-data-table-mob {
    display: none !important;
}

@media only screen and (max-width: 680px) {
    div.device-btns-mob-hidden,.device-data-table-hide-mob {
        display: none !important;
    }
    div.device-btns-large-hidden {
        display: flex !important;
    }
    .device-data-table-mob {
        display: block !important;
        .device-data-mob-btn {
            max-width: 22px;
            margin-right: 5px;
            &:focus {
                outline: none;
            }
        }
        .device-data-mob-modal {
            .close {
                position: relative;
                top: -20px;
                float: none !important;
            }
        }
    }
    .device-img{
        img{
            width: 40%;
        }
    }
    .device-slider {
        .device-item {
            img {
                max-width: 100px !important;
            }
        }
    }
}