.session-box {
  // .modal-content {
  //   width: 95%;
  //   max-width: 750px;
  //   max-height: 90vh;

  //   margin: 0 auto;
  // }
  &__input {
    max-width: 700px;
    width: 85%;
    margin: 0 auto;
    p {
      color: #ffffff;
      // font-size: 20px;
      font-size: 0.95em;
    }
    label {
      color: #ffffff;
      // font-size: 20px;
      font-size: 0.95em;
      padding-top: 10px;
    }
    .strainList {
      background: url("../../assets/images/select-dropdown-bg.png") no-repeat
        98% 55%;
    }
    select {
      padding: 8px;
      background: transparent;
      border-color: #ffffff;
      color: white;
      font-size: 16px;
      appearance: none;
      margin-bottom: 15px;
      option {
        color: #e58260;
        background: #332d3b;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.main-heading {
  color: #e58260;
  font-size: 32px;
}
.device-box {
  &__info {
    h3 {
      color: #e58260;
      font-size: 32px;
    }
    p {
      color: white;
      font-size: 18px;
    }
  }
  &__choice {
    color: #e58260;
    font-size: 20px;
    text-transform: uppercase;
    hr {
      width: 323px;
      border: 1px solid #e58260;
    }
  }
  &__shop {
    p {
      color: white;
      font-size: 18px;
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

.add-btn {
  background: transparent;
  border: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
}
.device-content {
  width: 100%;
}

@media only screen and (max-width: 680px) {
  .device-box {
    &__info {
      h3 {
        color: #e58260;
        font-size: 22px;
      }
    }
  }
}
