.smart-path-pop-btns-wrap .btn {
  padding-left: 9px !important;
  padding-right: 9px !important;
  font-size: 0.65em !important;
}

.smart-btn {
  background: transparent !important;
  border: none;
  box-shadow: none;
  color: white;
  font-size: 24px;
  &:focus,
  &:active {
    outline: none;
    color: white;
  }
}
.path-slidecontainer {
  width: 100%;
}

.path-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #241f28;
  border: 1px solid rgba(255, 255, 255, 0.288);
  border-radius: 30px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.path-slider:hover {
  opacity: 1;
}

.path-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e58260;
  cursor: pointer;
}

.path-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
.edit-btn {
  color: #e58260;
  font-size: 16px;
}
.path-box {
  // .modal-content {
  //   max-width: 775px;
  //   margin: 0 auto;
  //   width: 90%;
  // }
  .modal-content {
    max-height: 90vh;
  }
  &.modal {
    top: 0 !important;
  }
  &__info {
    max-width: 475px;
    margin: 0 auto;
    width: 90%;
    img {
      width: 100%;
    }
    p {
      color: white;
      font-size: 18px;
    }
    span {
      color: #e58260;
    }
  }
}

@media only screen and (max-width: 680px) {
  .path-box {
    &__info {
      p {
        font-size: 14px;
      }
    }
  }
}
