.main-content {
  padding-left: 310px;
  padding-top: 70px;
  padding-right: 60px;
}

@media only screen and (max-width: 1100px) {
  .main-content {
    padding: 80px 10px 50px 10px;
  }
}

@media only screen and (max-width: 680px) {
  .main-content {
    padding-top: 60px;
    padding-bottom: 10px;
  }
}