.home-screen{
  
    input{
        background: transparent;
        box-shadow: none;
        border: 1px solid rgba(255, 255, 255, 0.541);
        border-radius: 30px;
        padding: 8px 20px;
        width: 40%;
        color: white;
        &:focus{
            outline: none;
        }
    }
    &__video{
        
        img{
            cursor: pointer;
            width: 100%;
        }
        &-img{
            padding-right: 40px !important;
        }
        &-slider{
            padding-left: 0px !important;
            .slick-dots li button:before{
                font-size: 15px !important;
                color: white ;
            }
            .slick-dots li.slick-active button:before
            {
                color: #e58260;
            }
            .slick-prev:before, .slick-next:before{
                color: #e58260 !important;
            }
            .slick-prev, .slick-next{
                top: 107% !important;
                z-index: 1;
            }
            .slick-prev {
                left: 12px;
            }
            .slick-next {
                right: 5px;
            }
           
            
        }
    }
    &__content{
        &-overlay{
            background: rgba(54, 54, 54,  0.8);
            width: 50%; 
            padding: 20px 0 !important;  
        }
        &-img{
            background: url('../../assets/images/accessoriesbg.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            p{
                color: white;
                font-size: 22px;
                text-transform: uppercase;   
            }
            h2{
                font-weight: 700; 
            }
            a{
                margin:  0 auto;
            }
        

        }
        &-description{
            p{
                color: #ffffff;
                font-size: 14px;
            }

        }
    }
}

@media only screen and (max-width: 990px) {
    .home-screen{
        padding: 0 15px;
        input{
            width: 100%;
        }
        &__content{
            padding: 0 15px;
            &-overlay{
                width: 100%;
                padding:20px 0;
            }
            &-img{
                p{
                    font-size: 16px;
                }
                h2{
                    font-size: 22px !important;
                }
            }
        }
        &__video{
            padding: 0 15px;
            &-img{
                padding-right: 0px !important;
            }
        }
    }
}