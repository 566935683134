.info{
    &__support{
        h4{
            color: white;
            font-size: 20px;
        }
        p{
            background-color: #332D3B;
            color: #E58260;
            padding: 10px 40px;
            font-size: 36px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}