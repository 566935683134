.signup{
    padding-top: 100px;
    &__logo img{
width: 100%;
    }
    &__field{
        width: 25%;
        p{
            text-align: center;
            color: #ffffff;
            font-size: 20px;
        }
        button{
            margin: 0 auto;
            display: flex;
        }
    }
    &__description{
        padding: 100px 0 80px 0;
    }
}
@media only screen and (max-width: 880px) {
    .signup{
        &__field{
            width: 90%;
        }
        &__logo{
            display: flex;
            justify-content: center;
         img{
            width: 85%;
            }
        }
        &__description{
            padding: 40px 0;
        }
    }
}