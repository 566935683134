.device-list-loader-lg {
  position: relative;
  top: 0px;
  display: inline-block;
}

.highlight-device {
  color: white !important;
}

.mobile-highlight-device {
  color: #e58260 !important;
}
.sidebaropen {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  background-color: #332d3b;
  width: 278px;
  height: 100vh;
  overflow: auto;
  left: 0;
  z-index: 9;
  .logo-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #332d3b;
    height: 110px;
    img {
      // max-height: 60px;
      // max-width: 44px;
      width: 100%;
    }
  }
  .brand {
    overflow: hidden;
    h2 {
      position: relative;
      transform: translateX(0);
      opacity: 1;
      transition: 0.3s;
      transition-delay: 0.3s;
      margin-top: 15px;
      color: #4e0050;
      font-weight: 400;
      font-size: 24px;
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E58260;
  }
}

.navigation {
  .device-list-wrp {
    position: relative;
    text-align: center;
    display: block;
    .device-list-loader {
      position: absolute;
      position: absolute;
      top: 1px;
      left: 8px;
    }
  }
  .nav-block {
    .nav-link {
      white-space: nowrap;
      opacity: 1;
      transition: 0.3s;
      transition-delay: 0;
    }
  }
  // &:after {
  //   content: " ";
  //   position: absolute;
  //   top: 135px;
  //   margin-top: -2px;
  //   right: 15px;
  //   width: 0;
  //   height: 0;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-top: 10px solid #e58260;
  // }
  select {
    width: 100%;
    background: #201b23;
    border-color: #e58260;
    color: white;
    font-size: 20px;
    appearance: none;
    padding: 12px 0px 12px 30px;

    cursor: pointer;
    option {
      color: #e58260;
      background: #332d3b;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 1100px) {
  .mobile-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: #332d3b;
    height: 70px;

    p {
      font-size: 26px;
      text-transform: uppercase;
      color: #e58260;
      padding: 0;
      margin: 0;
    }

    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 11px solid;
      border-right: 11px solid transparent;
      border-bottom: 0;
      border-left: 11px solid transparent;
      display: none;
    }
    button#dropdownMenuButton {
      background: transparent;
      border: none;
      color: #b86d57;
      .pair-device-icon {
        max-width: 20px;
      }
      &:focus {
        outline: none !important;
        box-shadow: none;
      }
    }
  }
  .dropdown-menu.show {
    background: #241f28fc;
    width: 50vw;
    border: 1px solid #e58260;
    left: -20px !important;
    max-height: 120px;
    a {
      font-size: 0.75em !important;
    }
  }
  .dropdown.show:focus {
    outline: none;
  }
  .sidebaropen {
    position: fixed;
    top: 50px;
    left: 0;
    transition: all 0.3s;
    transform: translateX(-320px);
    opacity: 0;
    visibility: hidden;
    width: 80%;
    max-width: 280px;
    overflow-y: auto;
    padding-bottom: 50px;
    &.active {
      transform: translateX(0) !important;
      opacity: 1 !important;
      visibility: visible !important;
      z-index: 999;
    }
  }
  .toggle-btn {
    background: transparent;
    color: #e58260;
    box-shadow: none;
    border: none;
    font-size: 30px;
    padding-left: 20px;
    &:focus {
      outline: none;
      color: #e58260;
    }
  }
  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99;
  }
  .navigation {
    &:after {
      display: none;
    }
  }
}

@media only screen and (max-width: 680px) {
  .mobile-header {
    height: 50px;
    .dropdown-toggle {
      &::after {
        border-top-width: 9px;
        border-right-width: 9px;
        border-left-width: 9px;
      }
    }
  }
  .toggle-btn {
    font-size: 1.5625em;
  }
}
.drop-down-width {
  width: 70% !important;
}

