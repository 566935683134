.path-slidecontainer {
    input[type="range"] { 
        position: relative;
        overflow: hidden;
        height: 27px;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            box-shadow: -101vw 0 0 100vw #32393f;
        }
    }
    
}

.smart-path{
    &__stats{
        h3{
            color: #E58260;
            font-size: 30px;
        }
        p{
            font-size: 16px;
            color: white;
        }
    }
    &__box{
        background-color: rgba(51, 45, 59, 0.658);
        padding: 20px;
        .smart-icon{
            color: #E58260;
            font-size: 18px;
        }
        
    }
}

@media only screen and (max-width: 680px) {
    .smart-path{
        &__stats{
            h3 {
                font-size: 20px;
            }
        }
    }
}