.slide-text{
    color: #E58260;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
}

@media only screen and (max-width: 680px) {
    .slide-text {
        font-size: 1em;
    }
}